import './login.scss';

import * as dompack from 'dompack';
import { RPCFormBase, registerHandler } from '@mod-publisher/js/forms';
import WRDAuthProvider from "@mod-wrd/js/auth";
import * as whintegration from '@mod-system/js/wh/integration';

dompack.onDomReady(() => {
  if (!document.documentElement.classList.contains('page-login'))
    return;
});

class LoginForm extends RPCFormBase {
  constructor(node) {
    super(node);
    this.node = node;

    if (dompack.debugflags['debug'])
      this.invokeRPC('debugprefill');
  }

  onSubmitFailed(errors, result) {
    console.error(result);
  }

  async onSubmitSuccess() {
    location.reload();
  }
}

class ForgotPasswordForm extends RPCFormBase {
  constructor(node) {
    super(node);
    this.node = node;

    if (dompack.debugflags['debug'])
      this.invokeRPC('debugprefill');
  }

  onSubmitFailed(errors, result) {
    console.error(result);
  }
}

class PasswordResetForm extends RPCFormBase {
  constructor(node) {
    super(node);
    this.node = node;

    if (dompack.debugflags['debug'])
      this.invokeRPC('debugprefill');
  }

  getFormExtraSubmitData() {
    return { requesturl: whintegration.config.obj.requesturl };
  }

  onSubmitFailed(errors, result) {
    if (result.invaliduser) {
      alert('Er ging helaas iets mis bij de actie. Probeer het later nog eens of neem contact met ons op als het probleem zich blijft voordoen.');
      console.error(result);
    }
  }
}

registerHandler('lucrum:loginform', node => new LoginForm(node));
registerHandler('lucrum:forgotpasswordform', node => new ForgotPasswordForm(node));
registerHandler('lucrum:passwordresetform', node => new PasswordResetForm(node));
